@media screen and (max-width: 900px) and (-webkit-min-device-pixel-ratio:1) {


    #shell {
        width: 150px !important;
        height: 150px !important;
        text-align: center;
        padding-bottom: 20px;
    }
    #pennzoil {
        width: 175px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
    #quakerstate {
        width: 175px !important;
        height: 150px !important;
        text-align: center;
        padding-bottom: 30px;
    }
    #jiffylube {
        width: 250px !important;
        height: 125px !important;
        text-align: center;
        padding-bottom: 10px;
    }
    #churchschicken {
        width: 175px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
    #spiritairlines {
        width: 350px !important;
        height: 250px !important;
        text-align: center;
        padding-bottom: 125px;
    }
    #scanaenergy {
        width: 250px !important;
        height: 250px !important;
        text-align: center;
        padding-bottom: 125px;
    }
    #marines {
        width: 175px !important;
        height: 175px !important;
        text-align: center;
        padding-bottom: 20px;
    }
    #gco {
        width: 450px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
}


@media screen and (max-width: 460px) and (-webkit-min-device-pixel-ratio:1) {


    #shell {
        width: 150px !important;
        height: 150px !important;
        text-align: center;
        padding-bottom: 20px;
    }
    #pennzoil {
        width: 175px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
    #quakerstate {
        width: 175px !important;
        height: 150px !important;
        text-align: center;
        padding-bottom: 30px;
    }
    #jiffylube {
        width: 250px !important;
        height: 125px !important;
        text-align: center;
        padding-bottom: 10px;
    }
    #churchschicken {
        width: 175px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
    #spiritairlines {
        width: 350px !important;
        height: 250px !important;
        text-align: center;
        padding-bottom: 125px;
    }
    #scanaenergy {
        width: 250px !important;
        height: 250px !important;
        text-align: center;
        padding-bottom: 125px;
    }
    #marines {
        width: 175px !important;
        height: 175px !important;
        text-align: center;
        padding-bottom: 20px;
    }
    #gco {
        width: 450px !important;
        height: 125px !important;
        text-align: center;
        /*padding-bottom: 10px;*/
    }
}
